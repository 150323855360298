import {Col, Row} from 'react-bootstrap';
import React from 'react';

const styles = {
     item: {
      color: 'white',
      fontSize: '25px',
      textAlign: 'center',
    },
    headerItem: {
      fontWeight: 'bold',
      color: '#c8f124',
      fontSize: '30px',
      textAlign: 'center',
    }
  };

function Mkx3() {
    return(
        <div>
            <Row  xs={1} md={1} lg={2} className="g-4">
            <img src="img/cars/mkxiii.png"  className='img-fluid' alt='mkx3'></img>
            <Col>
            <br></br><br></br><h2>Mkx. III</h2>
            <p>Der Rennbolide aus der Saison 2019/20 und 2020/21.</p></Col></Row>
            <br />
            <Row xs={1} md={4}>
              <Col xs={6} md={3}>
                <div style={styles.headerItem}>Leistung</div>
                <div style={styles.item}>80 kW</div>
              </Col>
              <Col xs={6} md={3}>
                <div style={styles.headerItem}>Gewicht</div>
                <div style={styles.item}>215kg</div>
              </Col>
              <Col xs={6} md={3}>
                <div style={styles.headerItem}>Top-Speed</div>
                <div style={styles.item}>107km/h</div>
              </Col>
              <Col xs={6} md={3}>
                <div style={styles.headerItem}>0-100 km/h</div>
                <div style={styles.item}>4.0s</div>
              </Col>
            </Row>
            <hr />
           
        </div>
    )
} 

export default Mkx3;