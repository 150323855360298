import Row from 'react-bootstrap/Row'

function Garage() {
    return(
        <div>
            <h2>Unsere Fahrzeuge</h2>
            <hr></hr>
            <h3>Mkx. V</h3>
			<a href="/Mkx5" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Row xs={1} md={2} className='g-4'> 
                <img src='img\cars\mkxv.png' className="img-fluid"  ></img>
                <p>Unser 5 Fahrzeug mit welchem wir erfolgreich, alle Disziplinen bei Formula Student Germany gefahren sind. Klicke auf das Bild um mehr zu erfahren.</p>
            </Row></a>
            <hr></hr>
            <h3>Mkx. DV</h3>
            <a href="/Mkx505" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Row xs={1} md={2} className='g-4'> 
                <img src='img\cars\mkxdv2.png' className="img-fluid"  ></img>
                <p>Der Prototypenträger für unser neues Autonomes System in der Saison 2022/23. Klicke auf das Bild um mehr zu erfahren.</p>
            </Row></a>
            <hr></hr>
            <h3>Mkx. IV</h3>
            <a href="/Mkx4"style={{ textDecoration: 'none', color: 'inherit' }}>
            <Row xs={1} md={2} className='g-4'>
            <img src='img\cars\mkxiv.png' className="img-fluid"  ></img>
                <p>Unser 4. Fahrzeug in der Saison 2021/22, mit welchen wir zum ersten Mal erfolgreich bei Formula Student Germany gefahren sind. Klicke auf das Bild um mehr zu erfahren. </p>
            </Row></a>
            <hr></hr>
            <h3>Mkx. III</h3>
            <a href="/Mkx3" style={{ textDecoration: 'none', color: 'inherit' }}> 
            <Row xs={1} md={2} className='g-4'>
            <img src='img/cars/mkxiii2.png' className="img-fluid" ></img>
                <p>Der Rennbolide aus der Saison 2019/20 und 2020/21. Klicke auf das Bild um mehr zu erfahren.</p>
            </Row ></a>
            <hr></hr>
            <h3>Mkx. II</h3>
            <a href="/Mkx2" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Row xs={1} md={2} className='g-4'> 
            <img src='img/cars/mkxii2.png' className="img-fluid" ></img>
                <p>Das Fahrzeug für die Saison 2018/19. Klicke auf das Bild um mehr zu erfahren.</p>
            </Row></a>
            <hr></hr>
            <h3>Mkx. I</h3>
            <a href="/Mkx1" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Row xs={1} md={2} className='g-4'>
            <img src='img/cars/mkxi2.png' className="img-fluid" ></img>
                <p>Unser erstes Fahrzeug mit welchem wir die Saison 2017/18 bestritten haben. Klicke auf das Bild um mehr zu erfahren.</p>
            </Row></a>


        </div>
        )
} 

export default Garage;