import {Card, Col, Row} from "react-bootstrap";

function SpCard(props) {
    return(
        <a href={props.href}><Col>
        <Card>
            <Card.Img variant="top" src={props.src}/>
            <Card.Body>
                <Card.Title>{props.firma}</Card.Title>
                <Card.Text>{props.text}</Card.Text>
            </Card.Body>
        </Card></Col></a>
    )
}

function Home() {
    return (
        <div>
        <h1>Herzlich Willkommen beim T.U.C. Racing Team.</h1>
        <p>Wir sind die größte Hochschulgruppe der Technischen Universität Chemnitz. Unser Ziel ist es, einen Rennwagen mit E-Motor zu entwickeln, der die Grenzen der Geschwindigkeit und Technologie neu definiert und uns in den internationalen Wettbewerben an die Spitze bringt.</p>
    <br />
    <iframe width="1000" height="500" src="https://www.youtube.com/embed/hPfaNRScPQM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <br/><br/>
    <h2>Unsere starken Partner</h2>
    <Row xs={1} md={2} lg={3} className="g-4">
        <SpCard  firma="Siemens" src="../img/startpage/siemens.png" href="https://www.siemens.com/de/de.html" />
        <SpCard  firma="Altium" src="../img/startpage/altium.png" href="https://www.altium.com/de" />
        <SpCard  firma="IAV" src="../img/startpage/iav.png" href="http://www.iav.com/karriere" />
        <SpCard  firma="Arc Solutions" src="../img/startpage/arcsolutions.png" href="http://www.arcsolutions.de" />
        <SpCard  firma="Bürklin" src="../img/startpage/buerklin.png" href="https://www.buerklin.com/de/" />
        <SpCard  firma="Rhode und Schwarz" src="../img/startpage/rs.png" href="https://rohde-schwarz.com" />

    </Row>    
    </div>
    )
}

export default Home;