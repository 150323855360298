import Row from 'react-bootstrap/Row'

function Blog() {
    return(
        <div>
        <h1>News</h1>
        <hr></hr>
        <h2>Rollout Mkx. V - Save the Date</h2>
        <br></br>
        <Row xs={1} md={2} lg={2} className="g-4">
        <div><img src="img/blog/rollout.png" className="img-fluid" border="0" alt='Rollout'/></div>
        <p>
        Halten Sie sich den 29. Mai frei. An diesem Tag würden wir uns freuen, Sie bei unserem Rollout willkommen zu heißen. Dort möchten wir Ihnen unser neues Fahrzeug vorstellen und Ihnen einen Überblick über die geplanten Events in dieser Saison geben. Wir hoffen auf Ihr zahlreiches Erscheinen!
        </p>
        </Row><br/>
        <p style={{ textAlign: 'right' }}>05.01.24</p>
        <hr></hr>
        <h2>Ihr Beitrag gibt uns den Turbo für unsere Rennsportträume</h2>
        <br></br>
        <Row xs={1} md={2} lg={2} className="g-4">
        <div><img src="img/blog/crowdfunding.png" className="img-fluid" border="0" alt='Crowdfunding'/></div>
        <p>
        Aktuell läuft unsere Crowdfunding-Aktion bei der Volksbank. Nur mit Ihrer Unterstützung können wir unser Ziel von 2.000 € erreichen. Wir würden uns freuen, wenn Sie den Link teilen und uns dabei helfen, unser Ziel zu erreichen. 
        <br></br><br></br><a href="https://www.viele-schaffen-mehr.de/projekte/https-tuc-racing-de">https://www.viele-schaffen-mehr.de/projekte/https-tuc-racing-de</a>
        </p>
        </Row><br/>
        <p style={{ textAlign: 'right' }}>03.01.24</p>
        <hr></hr>
        <h2>Unsere Recruiting Phase</h2>
        <br></br>
        <Row xs={1} md={2} lg={2} className="g-4">
        <div><img src="img/blog/recruiting.png" className="img-fluid" border="0" alt='Recruiting'/></div>
        <p>
        Im frühen Oktober starteten wir unseren Rekrutierungsprozess und wagten in diesem Jahr einen innovativen Ansatz. Um eine breite Palette von Personen anzusprechen, gestalteten wir eine Woche voller unterschiedlicher Aktivitäten. Während dieser Veranstaltungen hatten wir die Gelegenheit, mit potenziellen Interessenten in Kontakt zu treten und ihnen umfassende Einblicke zu gewähren. 
        <br></br><br></br>Der Höhepunkt dieser Woche fand am Sachsenring statt, bei dem unsere Interessenten erstmals die Gelegenheit hatten, sich mit unseren Sponsoren zu vernetzen und unser Fahrzeug in Aktion zu erleben. Mit Freude können wir verkünden, dass wir 20 neue, hochmotivierte Mitglieder erfolgreich für unser Team gewinnen konnten.
        </p>
        </Row><br/>
        <p style={{ textAlign: 'right' }}>10.12.23</p>
        <hr></hr>
        <h2>FS Italy</h2>
        <br></br>
        <Row xs={1} md={2} lg={2} className="g-4">
        <div><img src="img/blog/italy.png" className="img-fluid" border="0" alt='Italy' /></div>
        <p>
        Sofort nach dem Event in der Schweiz begann unser Team damit, unser Auto auf den autonomen Fahrmodus umzurüsten, um am Formula Student Event in Italien teilzunehmen. Vom 11.07. bis 17.07.2023 nahmen wir dort als reines Driverless Team teil. Obwohl wir weder in den statischen noch in den dynamischen Disziplinen Erfolge verbuchen konnten, haben wir dennoch wertvolle Erfahrung gesammelt und unseren Teamgeist sowie unsere Motivation gestärkt. Dies wird uns dabei helfen, in der kommenden Session ein zuverlässiges Rennauto zu präsentieren. 
        </p>
        </Row><br/>
        <p style={{ textAlign: 'right' }}>05.08.23</p>
        <hr></hr>
        <h2>FS Schweiz</h2>
        <br></br>
        <Row xs={1} md={2} lg={2} className="g-4">
        <div><img src="img/blog/swiss.png" className="img-fluid" border="0" alt='Swiss'/></div>
        <p>
        Vom 30.06. bis 05.07.2023 nahmen wir mit unserem Rennauto am Formula Student Event in der Schweiz teil. Während dieser Veranstaltung hatten wir die Möglichkeit, wertvolles Feedback zu unserem Auto und unserer Leistung in den statischen Disziplinen zu sammeln. Besonders erfreulich war, dass wir in der Kategorie "Cost and Manufacturing" den 2. Platz erlangen konnten. Leider wurden wir aufgrund technischer Probleme nur zu einer Inspektion zugelassen und konnten somit nicht an den dynamischen Disziplinen teilnehmen. Trotzdem haben wir wertvolle Erfahrung gesammelt, die uns bei zukünftigen Wettbewerben zugutekommen wird.
        </p>
        </Row><br/>
        <p style={{ textAlign: 'right' }}>05.08.23</p>
        </div>
    )
}

export default Blog;
