import {Col, Row} from 'react-bootstrap';
import React from 'react';

const styles = {
     item: {
      color: 'white',
      fontSize: '25px',
      textAlign: 'center',
    },
    headerItem: {
      fontWeight: 'bold',
      color: '#c8f124',
      fontSize: '30px',
      textAlign: 'center',
    }
  };

function Mkx2() {
    return(
        <div>
            <Row  xs={1} md={1} lg={2} className="g-4">
            <img src="img/mkx2/mkx2r.png"  className='img-fluid' alt='mkx2'></img>
            <Col>
            <br></br><br></br><h2>Mkx. II</h2>
            <p>Das Fahrzeug für die Saison 2018/19.</p></Col></Row>
            <br />
            <Row xs={1} md={4}>
              <Col xs={6} md={3}>
                <div style={styles.headerItem}>Leistung</div>
                <div style={styles.item}>80 kW</div>
              </Col>
              <Col xs={6} md={3}>
                <div style={styles.headerItem}>Gewicht</div>
                <div style={styles.item}>215kg</div>
              </Col>
              <Col xs={6} md={3}>
                <div style={styles.headerItem}>Top-Speed</div>
                <div style={styles.item}>107km/h</div>
              </Col>
              <Col xs={6} md={3}>
                <div style={styles.headerItem}>0-100 km/h</div>
                <div style={styles.item}>4.0s</div>
              </Col>
            </Row>
            <hr />
            <Row  xs={1} md={1} lg={2} className="g-6">
            <img src="img/mkx2/dtmkx2.png"  width='100%' className='img-fluid' alt='dtdv'></img>
            <Col>
            <br></br><br></br><h2>Drivetrain</h2>
            <ul style={{ listStyleType: 'square' }}>
                <li>Kettenradgetriebe</li>
                <li>Übersetzungsverhältnis 3,9</li>
                <li>900 Nm Spitzendrehmoment</li>
                <li>Sperrdifferential</li>
            </ul>
           </Col>
           <img src="img/mkx2/ptmkx2.png"  width='100%' className='img-fluid' alt='ptdv'></img>
            <Col>
            <br></br><br></br><h2>Powertrain</h2>
            <ul style={{ listStyleType: 'square' }}>
                <li>DTI HV-500 Wassergekühlter Inverter</li>
                <li>800 VDC max. Spannung</li>
                <li>bis zu 95% Effizienz</li>
                <li>CWA100 Kühlpumpe</li>
            </ul>
           </Col>
           <img src="img/mkx2/akkumkx2.png"  width='100%' className='img-fluid' alt='akkudv'></img>
            <Col>
            <br></br><br></br><h2>Akkumulator</h2>
            <ul style={{ listStyleType: 'square' }}>
                <li>x432 21700 Li-ion Rundzellen</li>
                <li>600V Akkuspannung (144s3p)</li>
                <li>6,8 kWh Energie</li>
                <li>48 V DC/DC mit 1kW Leistung</li>
                <li>30km Reichweite</li>
            </ul>
           </Col>
           <img src="img/mkx2/mkxfr.png"  width='100%' className='img-fluid' alt='framedv'></img>
            <Col>
            <br></br><br></br><h2>Frame</h2>
            <ul style={{ listStyleType: 'square' }}>
                <li>Stahlrohrrahmen aus natlosen Präzisionsstahlrohren</li>
                <li>Aliminiumwabe als Aufpralldämpfer</li>
                <li>ultraleichte Carbonverkleidung</li>
                <li>optimiert auf Packaging-Efficiency</li>
            </ul>
           </Col>
           <img src="img/mkx2/susmkx2.png"  width='100%' className='img-fluid' alt='susdv'></img>
            <Col>
            <br></br><br></br><h2>Suspension</h2>
            <ul style={{ listStyleType: 'square' }}>
                <li>Continental C19-Reifen</li>
                <li>OZ Magnesiumfelgen</li>
                <li>Doppelquerlenker-Aufhängung</li>
                <li>5-achs-gefräste Aluminiumradträger</li>
                <li>Öhlins TTX 25 MkII Dämpfer</li>
            </ul>
            </Col>
            <img src="img/mkx2/mkx2cut.png"  width='100%' className='img-fluid' alt='pedaldv'></img>
            <Col>
            <br></br><br></br><h2>Schnittansicht</h2>
            <ul style={{ listStyleType: 'square' }}>
               
            </ul>
           </Col>
           </Row>
        </div>
    )
} 

export default Mkx2;